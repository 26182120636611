const theme = {
  variableColor: {
    primaryYellow: '#FDC126',
    primaryGreen: '#5DADAE',
    borderGray: '#f0f0f0',
    primaryBlue: '#0F6BFF',
    textColor: '#000000',
  },
};

export default theme;
