/* eslint-disable array-callback-return */
/* eslint-disable prefer-const */
/* eslint-disable no-else-return */
/* eslint-disable consistent-return */
import size from 'lodash/size';
// import isEmpty from 'lodash/isEmpty';
import isEmpty from 'lodash/isEmpty';
import StateHelper from './stateHelper';

/* eslint-disable arrow-body-style */
/* eslint-disable no-undef */
export const formatAddress = (city, state, zip) => {
  // eslint-disable-next-line consistent-return
  const lineOne = [city, state]
    .filter(element => {
      return element !== null && element !== '' && element !== undefined;
    })
    .join(', ');
  const address = [lineOne, zip].join(' ');
  return address;
};

export const removeEmptyValues = obj => {
  Object.keys(obj).forEach(key => {
    if (obj[key] === null) {
      obj[key] = '';
    }
  });
  return obj;
};

export const trimString = (limit, str) => {
  if (str) {
    const displayText = str.substring(0, limit || undefined);
    if (size(str) > limit) {
      return displayText.concat('...');
    }
    return displayText;

    // eslint-disable-next-line no-else-return
  } else {
    return null;
  }
};

export const getStateName = shortName => {
  if (shortName) {
    const stateObj = StateHelper.STATES.filter(state => {
      return state.label === shortName;
    });
    return stateObj[0].value;
  }
  return null;
};

export const arrayRemove = (arr, value) => {
  return arr.filter(element => {
    return element !== value;
  });
};

export const isArrayelementPresent = (arr, id) => {
  return arr.includes(id);
};

export const formatPhoneNumber = phoneNumberString => {
  if (phoneNumberString) {
    // const cleaned = phoneNumberString.replace(/\D/g, '');
    // const match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
    // console.log(match);
    // if (match) {
    return `${phoneNumberString.substring(0, 3)}-${phoneNumberString.substring(
      3,
      6,
    )}-${phoneNumberString.substring(6)}`;
    // }
  }
  return null;
};

// eslint-disable-next-line consistent-return
export const checkMandotoryFeilds = object => {
  if (!isEmpty(object) && isEmpty(object.license_number)) {
    const validationObj = {
      legal_name: object.legal_name,
      license_issuer: object.license_issuer,
      license_type: object.license_type,
      city: object.city,
      state_name: object.state_name,
      county_name: object.county_name,
      license_details: object.license_details,
      dba_name: object.dba_name,
    };
    const emptyMandotoryeilds = Object.values(validationObj).every(x => x === null || x === '');
    return !emptyMandotoryeilds;
  } else if (!isEmpty(object)) {
    return true;
  }
};

export const isValidState = state => {
  if (!isEmpty(state)) {
    return StateHelper.STATE_SHORT.includes(state);
  } else {
    return true;
  }
};

export const getDropdownValue = (label, arr) => {
  if (label) {
    const objArr = arr.filter(val => {
      return val.label === label;
    });
    return objArr;
  }
  return null;
};

export const getSingleDropdownValue = (label, arr) => {
  if (label) {
    const objArr = arr.filter(val => {
      return val.label === label;
    });
    return objArr[0];
  }
  return null;
};

export const cleanValues = (inputObject, operator) => {
  // eslint-disable-next-line no-unused-vars
  return Object.fromEntries(Object.entries(inputObject).filter(([_, v]) => v !== operator));
};

export const filterChanges = (comparisonObj, changedObj) => {
  let changes = {};
  // eslint-disable-next-line no-restricted-syntax
  for (let keys in comparisonObj) {
    if (comparisonObj[keys] !== changedObj[keys]) {
      changes[keys] = changedObj[keys];
    }
  }
  return changes;
};

export const genarateIdList = (inputArray, bulkIdList) => {
  let idList = bulkIdList;
  // eslint-disable-next-line for-direction, no-plusplus
  inputArray.map(item => {
    if (!bulkIdList.includes(item.id)) {
      idList.push(item.id);
    }
  });
  return idList;
};

export const clearIdList = (inputArray, bulkIdList) => {
  let idList = bulkIdList.filter(val => !inputArray.includes(val));
  return idList;
};

export const decodeJwt = token => {
  let base64Url = token.split('.')[1];
  let base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
  let jsonPayload = decodeURIComponent(
    window
      .atob(base64)
      .split('')
      .map(c => {
        // eslint-disable-next-line prefer-template
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
      })
      .join(''),
  );

  return JSON.parse(jsonPayload);
};

export const toTitleCase = value => {
  return value.replace(/\w\S*/g, txt => {
    return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
  });
};

export const formatFullName = (firstName, lastName) => {
  return `${firstName} ${lastName}`;
};

export const minsToTime = durationInMins => {
  const duration = durationInMins * 1000 * 60;
  let milliseconds = Math.floor((duration % 1000) / 100);
  let seconds = Math.floor((duration / 1000) % 60);
  let minutes = Math.floor((duration / (1000 * 60)) % 60);
  let hours = Math.floor((duration / (1000 * 60 * 60)) % 24);
  let days = Math.floor(duration / (1000 * 60 * 60 * 24));

  hours = hours < 10 ? `0${hours}` : hours;
  minutes = minutes < 10 ? `0${minutes}` : minutes;
  seconds = seconds < 10 ? `0${seconds}` : seconds;

  return `${days}d ${hours}h ${minutes}m`;
};
