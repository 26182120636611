import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import { Form, Image } from 'react-bootstrap';
import isNull from 'lodash/isNull';

import { StyleSearch } from '../../layouts/header/Header.styled';
import SearchIcon from '../../../assets/images/search.svg';

const DEBOUNCE_THRESHOLD = 500;
function PageHeader(props) {
  const { customClass, handleSearch, setSearchParameter, searchParameter, disabled, placeholder } =
    props;

  const timeoutHandler = useRef(null);

  const handleChange = e => {
    const { value } = e.target;
    if (isNull(value)) {
      setSearchParameter('');
    } else {
      setSearchParameter(value);
    }

    if (timeoutHandler.current) {
      clearTimeout(timeoutHandler.current);
    }

    timeoutHandler.current = setTimeout(() => {
      handleSearch(value);
    }, DEBOUNCE_THRESHOLD);
  };

  return (
    <Form
      onSubmit={e => {
        e.preventDefault();
      }}
    >
      <StyleSearch>
        <Image src={SearchIcon} />
        <Form.Control
          type="search"
          // eslint-disable-next-line react/destructuring-assignment, no-nested-ternary, no-unneeded-ternary
          placeholder={placeholder ? placeholder : props.reportType === 1 ? 'Search' : 'User Name'}
          className={`mb-3 ${customClass}`}
          aria-label="Search"
          value={searchParameter}
          onChange={handleChange}
          disabled={disabled}
        />
      </StyleSearch>
    </Form>
  );
}

PageHeader.propTypes = {
  customClass: PropTypes.string,
  reportType: PropTypes.number,
  disabled: PropTypes.bool,
  placeholder: PropTypes.string,
};

PageHeader.defaultProps = {
  customClass: '',
  reportType: 1,
  disabled: false,
  placeholder: '',
};

export default PageHeader;
