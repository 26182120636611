import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import isEmpty from 'lodash/isEmpty';

import { ThemeProvider } from 'styled-components';
import theme from './components/styles/Theme.styled';
import GlobalStyles from './components/styles/Global';
import AppRoutes from './routes';
import { AuthContextProvider } from './containers/auth/AuthContext';
import { getThemeData } from './apis/settingsApi';
import SettingsHelper from './helpers/features/SettingsHelper';

function App() {
  const isBackendAuthorized = useSelector(state => state.AUTH.isBackendAuthorized);

  const [mainTheme, setMainTheme] = useState(SettingsHelper.DEFAULT_THEME_DATA);

  const fetchThemeData = async (id = 0) => {
    const response = await getThemeData(id);
    if (response.success) {
      setMainTheme(response.data.theme_data);
      localStorage.setItem('theme', JSON.stringify(response.data.theme_data));
    }
  };

  // remove authorizer for theme (API is public)
  useEffect(() => {
    // if (isBackendAuthorized) {
    fetchThemeData();
    // }
    // }, [isBackendAuthorized]);
  }, []);

  return (
    <ThemeProvider theme={mainTheme}>
      <GlobalStyles />
      <AuthContextProvider>
        {/* <Header /> */}
        <AppRoutes />
        {/* <Footer /> */}
      </AuthContextProvider>
    </ThemeProvider>
  );
}

export default App;
