/* eslint-disable no-nested-ternary */
/* eslint-disable react/no-danger */
/* eslint-disable no-confusing-arrow */
/* eslint-disable react/prop-types */
import React, { useContext, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import moment from 'moment';

import TableRow from '../../common/table/TableRow';
import SearchLicenseHelper from '../../../helpers/features/SearchLicensesHelper';
import { formatFullName, minsToTime } from '../../../helpers/commonHelper';
import { ReportsContext } from '../../../containers/reports/ReportsContext';

function SearchLicenseRow({ data }) {
  const { isAdmin, reportType } = useContext(ReportsContext);

  return (
    <TableRow>
      {isAdmin && (
        <>
          <td>
            <span>
              {reportType === 1
                ? data.license_issuer === 'null'
                  ? SearchLicenseHelper.NO_VALUES
                  : data.license_issuer || SearchLicenseHelper.NO_VALUES
                : reportType === 2
                  ? formatFullName(data.first_name, data.last_name) || SearchLicenseHelper.NO_VALUES
                  : SearchLicenseHelper.NO_VALUES}
            </span>
          </td>
          <td>
            {reportType === 1
              ? data.state === 'null'
                ? SearchLicenseHelper.NO_VALUES
                : data.state || SearchLicenseHelper.NO_VALUES
              : reportType === 2
                ? data.company || SearchLicenseHelper.NO_VALUES
                : SearchLicenseHelper.NO_VALUES}
          </td>
          <td>
            <span>
              {reportType === 1
                ? data.log_date || SearchLicenseHelper.NO_VALUES
                : reportType === 2 || SearchLicenseHelper.NO_VALUES
                  ? moment(data.time).local().format('LLLL')
                  : SearchLicenseHelper.NO_VALUES}
            </span>
          </td>
          <td>
            <span>
              <span>
                {reportType === 1
                  ? data.added_count || SearchLicenseHelper.NO_VALUES
                  : reportType === 2
                    ? minsToTime(data.duration) || SearchLicenseHelper.NO_VALUES
                    : SearchLicenseHelper.NO_VALUES}
              </span>
            </span>
          </td>
          {reportType === 1 && (
            <>
              <td>
                <span>{data.update_count || SearchLicenseHelper.NO_VALUES}</span>
              </td>
              <td>
                <span>{data.delete_count || SearchLicenseHelper.NO_VALUES}</span>
              </td>
              <td>
                <span>
                  {data.upload_type === 'BULK'
                    ? 'Manual'
                    : 'Scraper' || SearchLicenseHelper.NO_VALUES}
                </span>
              </td>
            </>
          )}
          {reportType === 2 && (
            <td>
              <Link
                to={`/licenses-details/${data.id}`}
                state={{ rowData: data }}
                target="_blank"
                style={{ textDecoration: 'none', pointerEvents: 'none' }}
              >
                View
              </Link>
            </td>
          )}
        </>
      )}
    </TableRow>
  );
}

SearchLicenseRow.propTypes = {
  data: PropTypes.shape({
    user_id: PropTypes.number,
    company: PropTypes.string,
    first_name: PropTypes.string,
    last_name: PropTypes.string,
    status: PropTypes.string,
    duration: PropTypes.number,
    email: PropTypes.string,
    time: PropTypes.string,
  }).isRequired,
};

export default SearchLicenseRow;
