import replaceParameters from '../helpers/parameterHelper';
import { get, put, post, del } from '../libs/api/apiWrapper';
import apiRoutes from '../helpers/apiRoutes';

/**
 * Reports
 */

// eslint-disable-next-line consistent-return
export const getUserLoginRecords = async body => {
  try {
    const url = apiRoutes.REPORTS.USER_LOGIN_REPORT;
    const { data } = await post(url, body);
    return data;
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error(`error ${error}`);
  }
};

export const downloadUserLoginRecordsCSV = async body => {
  try {
    const url = `${apiRoutes.REPORTS.USER_LOGIN_REPORT}/download`;
    const { data } = await post(url, body);
    return data;
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error(`error ${error}`);
    throw error;
  }
};

// eslint-disable-next-line consistent-return
export const getCompanyList = async () => {
  try {
    const url = apiRoutes.REPORTS.USER_COMPANY_LIST;
    const { data } = await get(url);
    return data;
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error(`error ${error}`);
  }
};

// eslint-disable-next-line consistent-return
export const getLicenseLogs = async params => {
  try {
    const url = apiRoutes.REPORTS.LICENSE_LOGS_V2;
    const { data } = await get(url, { params });
    return data;
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error(`error ${error}`);
  }
};
