export default Object.freeze({
  LICENSE_UPDATE_LOGS_TITLE: 'License Update Logs',
  USER_LOGIN_REPORT_TITLE: 'User Logins Report',
  WEEKLY_LICENSE_REPORT_TITLE: 'Weekly License Report',
  LICENSE_UPDATE_LOGS_TABLE_HEADERS: [
    { label: 'License Issuer', key: 1, db_key: 'license_issuer' },
    { label: 'State Name', key: 2, db_key: 'state' },
    { label: 'Updated Date', key: 3, db_key: 'log_date' },
    { label: '# of Licenses Added', key: 4, db_key: 'added_count' },
    { label: '# of Licenses Updated', key: 5, db_key: 'update_count' },
    { label: '# of Licenses Removed', key: 6, db_key: 'delete_count' },
    { label: 'Upload Type', key: 7, db_key: 'upload_type' },
  ],
  USER_LOGIN_REPORT_TABLE_HEADERS: [
    { label: 'User Name', key: 1, db_key: 'first_name' },
    { label: 'Company', key: 2, db_key: 'company' },
    { label: 'Logged in Date', key: 2, db_key: 'time' },
    { label: 'Duration', key: 2, db_key: 'duration' },
    { label: 'Activity Log', key: 2, db_key: '' },
  ],
  USER_LOGIN_RECORDS_DEFAULT_LIMIT: 10,
  DEFAULT_SORTING_PARAMETER: 'time',
  DEFAULT_SORTING_PARAMETER_LICENSE: 'log_date',
  USER_LOGIN_RECORDS_STARTING_PAGE: 1,
  TOST_TYPE_SUCCESS: 'success',
  TOST_TYPE_ERROR: 'error',
  USER_PERMISSION_ROLE_ADMIN: 'ADMIN',
  GET_USER_LOGIN_RECORDS_DEFAULT_PARAMETERS: {
    page: 1,
    limit: 10,
    desc: ['time'],
  },
  GET_LICENSE_LOGS_DEFAULT_PARAMETERS: {
    page: 1,
    limit: 10,
    sort_by: 'log_date',
    sort_order: 'asc',
  },
  WEEKLY_LICENSES_TABLE: {
    TABLE_HEADERS: [
      { label: 'License Number', key: 1, db_key: 'license_number' },
      { label: 'Legal Name', key: 2, db_key: 'legal_name' },
      { label: 'DBA', key: 3, db_key: 'dba_name' },
      { label: 'Street', key: 4, db_key: 'street' },
      { label: 'City', key: 5, db_key: 'city' },
      { label: 'State', key: 6, db_key: 'state' },
      { label: 'State Name', key: 7, db_key: 'state_name' },
      { label: 'Zip', key: 8, db_key: 'zip' },
      { label: 'Country', key: 9, db_key: 'country_name' },
      { label: 'Country Code', key: 10, db_key: 'country_code' },
      { label: 'License Issuer', key: 11, db_key: 'license_issuer' },
      { label: 'License Type', key: 12, db_key: 'license_type' },
      { label: 'License Details', key: 13, db_key: 'license_details' },
      { label: 'License Designation', key: 14, db_key: 'license_designation' },
      { label: 'License Issue Date', key: 15, db_key: 'license_issue_date' },
      { label: 'License Expiration Date', key: 16, db_key: 'license_expiration_date' },
      { label: 'County Name', key: 17, db_key: 'county_name' },
      { label: 'Parent Name', key: 19, db_key: 'parent_name' },
      { label: 'Company Website', key: 20, db_key: 'company_website' },
      { label: 'Company Contact Name', key: 21, db_key: 'company_contact_name' },
      { label: 'Company Email', key: 22, db_key: 'company_email' },
      { label: 'Company Phone', key: 23, db_key: 'company_phone' },
      { label: 'Notes', key: 24, db_key: 'note' },
      { label: 'Last updated', key: 25, db_key: ['updated_at'] },
      { label: 'License Status', key: 26, db_key: 'status' },
      { label: '', key: 27 },
    ],
    NO_RECORDS_FOUND: 'No Records Found',
    DEFAULT_PARAMETERS: {
      page: 1,
      limit: 10,
      asc: ['legal_name'],
    },
    STARTING_PAGE: 1,
    DEFAULT_SORTING_PARAMETER: 'legal_name',
    LICENSE_RECORDS_DEFAULT_LIMIT: 10,
    USER_PERMISSION_ROLE_ADMIN: 'ADMIN',
  },
});
