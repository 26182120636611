import { useState } from 'react';
import { Formik } from 'formik';
import { Form } from 'react-bootstrap';
import * as yup from 'yup';
// import PropTypes from 'prop-types';
import { Link, useLocation } from 'react-router-dom';
import AppInput from '../common/input/AppInput';
import AppButton from '../styles/ButtonLink.styled';
import LoginHelper from '../../helpers/features/LoginHelper';
import { useAuth } from '../../containers/auth/AuthContext';
import ErrorNotification from '../common/errorNotification/ErrorNotification';

function LoginForm() {
  const auth = useAuth();

  const [isClicked, setIsClicked] = useState(false);

  const loginSchema = yup.object().shape({
    // password: yup
    //   .string()
    //   .min(3, 'Password is too short - should be 8 chars minimum.')
    //   .required('Password required'),
    email: yup.string().email('Please enter a valid email address.').required('Email is required'),
  });

  return (
    <Formik
      enableReinitialize
      initialValues={{ email: '', password: '' }}
      onSubmit={async values => {
        setIsClicked(true);
        auth.handleLogin(values);
      }}
      validationSchema={loginSchema}
    >
      {({
        values,
        errors,
        touched,
        handleSubmit,
        handleChange,
        handleBlur,
        isValid,
        // eslint-disable-next-line no-unused-vars
        validateOnMount,
        dirty,
      }) => (
        <Form onSubmit={handleSubmit} noValidate>
          <AppInput
            label={LoginHelper.LOGIN_FORM_EMAIL_LABLE}
            requied="*"
            type="email"
            name="email"
            placeholder={LoginHelper.LOGIN_FORM_EMAIL_PLACEHOLDER}
            helpText=""
            helpTextLink=""
            linkUrl=""
            onChangeInput={handleChange}
            onBlurInput={handleBlur}
            value={values.email}
            errorText={errors.email && touched.email ? errors.email : null}
          />
          <div className="password-reset">
            <Link className="app-link" to="/forgot-password">
              {LoginHelper.FORGET_PASSOWORD_HEADER} ?
            </Link>
          </div>
          <AppInput
            label={LoginHelper.LOGIN_FORM_PASSWORD_LABLE}
            requied="*"
            type="password"
            name="password"
            placeholder={LoginHelper.LOGIN_FORM_PASSWORD_PLACEHOLDER}
            helpText=""
            helpTextLink=""
            linkUrl=""
            onChangeInput={handleChange}
            onBlurInput={handleBlur}
            value={values.password}
            errorText={errors.password && touched.password ? errors.password : null}
          />
          <div className="d-grid">
            <AppButton type="submit" disabled={!(dirty && isValid) || isClicked}>
              {LoginHelper.LOGIN_BTN_TEXT}
            </AppButton>
          </div>
          {auth.message.type === 'error' && (
            <ErrorNotification errorMessage={auth.message.message} />
          )}
        </Form>
      )}
    </Formik>
  );
}

export default LoginForm;
