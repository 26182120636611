/* eslint-disable no-nested-ternary */
/* eslint-disable indent */
/* eslint-disable operator-linebreak */
/* eslint-disable arrow-body-style */
/* eslint-disable array-callback-return */
import React, { useContext, useState, useEffect } from 'react';
import { Container, Row, Col, Image, Form } from 'react-bootstrap';
import makeAnimated from 'react-select/animated';
import isEmpty from 'lodash/isEmpty';
import size from 'lodash/size';
import SweetAlert from 'react-bootstrap-sweetalert';
import { useNavigate } from 'react-router-dom';
import DatePicker from 'react-datepicker';
import moment from 'moment';

import ReportTableRow from './reportTableRow/ReportTableRow';
import { StylePage } from '../styles/Page.styled';
import TableComponet from '../common/table/Table';
import PaginationComponet from '../common/pagination/Pagination';
import PaginationDropdown from '../common/input/PaginationDropdown';
import PageHeader from '../common/pageHeader/PageHeader';
import Search from '../common/search/Search';
import TableHeader from '../common/table/TableHeader';
import TableBody from '../common/table/TableBody';
import SingleTableHeader from '../common/table/SingleTableHeader';
import FilterDropDown from '../common/input/stateFilterDropDown/FilterDropDown';
import MultiValue from '../common/input/stateFilterDropDown/MultiValue';
import Option from '../common/input/stateFilterDropDown/Options';

import SearchLicenseHelper from '../../helpers/features/SearchLicensesHelper';
import ReportsHelper from '../../helpers/features/ReportsHelper';
import { ReportsContext } from '../../containers/reports/ReportsContext';
import StateHelper from '../../helpers/stateHelper';
import StyleForm from '../common/input/Form.styled';

import FilterIcon from '../../assets/images/filter.svg';
import LodingIcon from '../../assets/images/spinner.gif';

import ConfirmIcon from '../../assets/images/confirme-icon.svg';
import DeleteIcon from '../../assets/images/delete-icon.svg';
import CalendarIcon from '../../assets/images/calender-icon.svg';
import Permissions from '../auth/Permissions';
import AppButton from '../styles/ButtonLink.styled';

function ReportTable({ reportType }) {
  const {
    userLoginReportData,
    totalUserLoginRecords,
    isLoadingUserLoginReportData,
    selectedOption,
    setSelectedOption,
    handleStateFilter,
    totalPages,
    isInlineEditing,
    isAdmin,
    isSelectAll,
    currentPage,
    setCurrentSortedTitle,
    handleSortedData,
    currentSortedTitle,
    handleSearch,
    setSearchParameter,
    searchParameter,
    handleRecordsPerPage,
    handleReportTablePageNavigation,
    nextPage,
    companyList,
    handleCompanyFilter,
    filteringDate,
    setFilteringDate,
    handleDateFilter,
    handleUserLoginReportCSVDownload,
  } = useContext(ReportsContext);

  const animatedComponents = makeAnimated();

  const [recordCount, setRecordCount] = useState(0);

  return (
    <Container>
      <StylePage>
        <Row>
          <Col md={6}>
            <PageHeader
              title={
                reportType === 1
                  ? ReportsHelper.LICENSE_UPDATE_LOGS_TITLE
                  : ReportsHelper.USER_LOGIN_REPORT_TITLE
              }
              subheader={`${totalUserLoginRecords} Records`}
            />
          </Col>
          <Col md={6} className="d-flex justify-content-end">
            <AppButton onClick={() => handleUserLoginReportCSVDownload()} fill="true">
              Download CSV
            </AppButton>
          </Col>
        </Row>
        <Row className="d-flex align-items-center">
          <Col md={2} className="mb-3">
            <StyleForm className="state-dropdown">
              <Image src={FilterIcon} />
              <FilterDropDown
                options={reportType === 1 ? StateHelper.STATES : companyList.docs}
                isMulti
                isCompanyDropdown
                isSearchable={false}
                isClearable={false}
                controlShouldRenderValue={false}
                closeMenuOnSelect={false}
                hideSelectedOptions={false}
                components={{ Option, MultiValue, animatedComponents }}
                onChange={value => {
                  if (reportType === 1) {
                    handleStateFilter(value);
                  } else {
                    handleCompanyFilter(value);
                  }
                  setSelectedOption(value);
                }}
                value={selectedOption}
                placeholder={reportType === 1 ? 'State' : 'Company'}
              />
            </StyleForm>
          </Col>
          {reportType === 2 && (
            <Col md={2} className="admin-sarch">
              <Permissions permissionRole="ADMIN">
                <Search
                  customClass="admin-search"
                  handleSearch={handleSearch}
                  setSearchParameter={setSearchParameter}
                  searchParameter={searchParameter}
                  reportType={reportType}
                />
              </Permissions>
            </Col>
          )}
          <Col md={2} className="mb-3 ">
            <StyleForm>
              <Permissions permissionRole="ADMIN">
                <div className="admin-filter-datepicke">
                  <DatePicker
                    placeholderText="Date"
                    className="form-control"
                    format="MM-DD-YYYY"
                    name="license_issue_date"
                    selected={filteringDate}
                    onChange={date => {
                      setFilteringDate(date);
                      handleDateFilter(moment(date).format('YYYY-MM-DD'));
                    }}
                    onKeyDown={e => {
                      e.preventDefault();
                    }}
                    isClearable
                  />
                  {!filteringDate && <Image src={CalendarIcon} />}
                </div>
              </Permissions>
            </StyleForm>
          </Col>
          <Col className="d-flex  mb-3 d-flex justify-content-end">
            <PaginationDropdown handleRecordsPerPage={handleRecordsPerPage} />
            {totalPages > 1 && (
              <PaginationComponet
                totalPages={totalPages}
                currentPage={currentPage}
                handleTablePageNavigation={handleReportTablePageNavigation}
                nextPage={nextPage}
              />
            )}
          </Col>
        </Row>
        <TableComponet
          className={isAdmin ? 'admin-licenes' : 'user-licences'}
          isSelectAll={isSelectAll}
          isInlineEditing={isInlineEditing}
        >
          <TableHeader>
            {reportType === 1
              ? ReportsHelper.LICENSE_UPDATE_LOGS_TABLE_HEADERS.map(item => {
                  return (
                    <SingleTableHeader
                      title={item}
                      key={item.key}
                      setCurrentSortedTitle={setCurrentSortedTitle}
                      handleFetchSortedData={handleSortedData}
                      currentSortedTitle={currentSortedTitle}
                    />
                  );
                })
              : ReportsHelper.USER_LOGIN_REPORT_TABLE_HEADERS.map(item => {
                  return (
                    <SingleTableHeader
                      title={item}
                      key={item.key}
                      setCurrentSortedTitle={setCurrentSortedTitle}
                      handleFetchSortedData={handleSortedData}
                      currentSortedTitle={currentSortedTitle}
                    />
                  );
                })}
          </TableHeader>
          <TableBody>
            {userLoginReportData.docs &&
              userLoginReportData.docs.map((item, i) => {
                return (
                  <ReportTableRow
                    data={item}
                    key={item.id}
                    countRecords={setRecordCount}
                    count={recordCount}
                    index={i}
                    reportType={reportType}
                  />
                );
              })}
            {isEmpty(userLoginReportData.docs) && !isLoadingUserLoginReportData && (
              <tr className="without-data">
                <td colSpan="24">
                  <p>{SearchLicenseHelper.NO_RECORDS_FOUND}</p>
                </td>
              </tr>
            )}
            {isLoadingUserLoginReportData && (
              <tr className="loding-data">
                <td colSpan="24">
                  <Image src={LodingIcon} sizes="50px" />
                </td>
              </tr>
            )}
          </TableBody>
        </TableComponet>
      </StylePage>
    </Container>
  );
}

export default ReportTable;
